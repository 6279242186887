<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="FormMSG(1, 'Format selection')"
		size="lg"
		hide-header-close
		centered
		cancel-variant="light"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div class="animated fadeIn">
			<div class="container-layout form">
				<b-row>
					<b-col cols="12">
						<b-form-group :label="FormMSG(4, 'Script')">
							<v-select
								v-model="scriptSelected"
								label="name"
								:options="scripts"
								:reduce="(option) => option.id"
								:clearable="false"
								:class="{ 'is-invalid': isSubmitted && $v.scriptSelected.$error }"
								@option:selected="handleScriptSelected"
							>
								<template #option="option">
									<div class="d-flex justify-content-start align-items-center">
										<b-img
											width="32"
											height="32"
											rounded
											:src="`svgs/bootstrap/filetype-${OUTPUT_FORMAT_EXPORT.at(option.outputFileFormat)}.svg`"
											:alt="OUTPUT_FORMAT_EXPORT.at(option.outputFileFormat)"
											v-if="option.id"
										/>
										<div class="d-flex flex-column justify-content-start">
											<div class="ml-2" :class="`${option.id ? 'label-script' : ''}`">
												{{ option.name }}
											</div>
											<div class="ml-2 sub-label-script" v-if="option.id">
												{{ getFileName(option.fileName) }} | {{ FormMSG(13, 'Output') }}:
												<span class="text-uppercase font-weight-bold">{{
													getExtensionScript(
														OUTPUT_FORMAT_EXPORT.at(option.outputFileFormat),
														option.customExtension,
														getFileExt(option.fileName)
													)
												}}</span>
											</div>
										</div>
									</div>
								</template>
							</v-select>
							<div v-if="isSubmitted && !$v.scriptSelected.required" class="invalid-feedback">
								{{ FormMSG(6, 'Script is required') }}
							</div>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
			<b-modal
				header-class="header-class-modal-doc-package"
				v-model="openLog"
				size="lg"
				ok-only
				ok-title-html
				centered
				:title="FormMSG(9, 'Log')"
				:ok-title="FormMSG(10, 'Close')"
				@ok="handleCloseLogWindow"
				@cancel="handleCloseLogWindow"
				@hidden="handleCloseLogWindow"
				no-close-on-backdrop
			>
				<b-card no-body>
					<b-card-text v-html="importLog" class="px-3 pb-3" />
				</b-card>
			</b-modal>
		</div>
		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-col sm="4" md="4" lg="2" xl="2">
					<b-button size="md" variant="light" @click="emitEventClose" block>
						{{ FormMSG(2, 'Cancel') }}
					</b-button>
				</b-col>
				<b-col sm="8" md="8" lg="3" xl="3">
					<b-button size="md" variant="primary" @click="sendReport" block :disabled="isLoadingSendMail">
						<div class="d-flex justify-content-center align-items-center">
							<b-spinner v-show="isLoadingSendMail" small></b-spinner>
							<div class="pl-2" style="margin-top: 1px">{{ FormMSG(3, 'Confirm') }}</div>
						</div>
					</b-button>
				</b-col>
			</div>
		</template>
	</b-modal>
</template>
<script>
// import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { isNil, formatFileName } from '@/shared/utils';
import { timeSheetReport, purchaseOrderReport, expenseReport, userReport, expenseItemReport } from '@/cruds/export.crud';
import globalMixin from '@/mixins/global.mixin';
// import axios from 'axios';
// import { generateRandomFilename } from '~utils';
import moment from 'moment';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';
import _ from 'lodash';
import { getFileExtension } from '@/shared/helpers';

export default {
	name: 'ScriptExportModal',
	mixins: [languageMessages, validationMixin, globalMixin],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		items: {
			type: Array,
			required: false,
			default: () => []
		},
		custom: {
			type: Object,
			required: false,
			default: null
		},
		exportType: {
			type: String,
			required: true,
			default: '' // 'expense', 'po', 'purchaseorder', 'timesheet', 'ts', 'user', 'expenseItem'
		}
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	data() {
		return {
			scriptSelected: null,
			isSubmitted: false,
			scripts: [],
			isLoadingSendMail: false,
			apiImportUrl: 'uploadImportFormatFile',
			openLog: false,
			importLog: '',
			OUTPUT_FORMAT_EXPORT: OUTPUT_FORMAT_EXPORT,
			script: null
		};
	},
	methods: {
		emitEventClose() {
			this.$emit('script-export-modal:closed');
		},
		async sendReport(e) {
			e.preventDefault();
			this.isSubmitted = true;

			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			await this.manageReport().finally(() => {
				this.$emit('script-export-modal:closed');
			});
			// if (this.exportType.toLowerCase() !== this.EXPORTNAME.USER) {
			// this.$emit('script-export-modal:closed');
			// }
		},
		getExtensionFile(filename) {
			return filename.substring(filename.lastIndexOf('.') + 1);
		},
		getFileName(filename) {
			return filename.substring(0, filename.lastIndexOf('.'));
		},
		async manageReport() {
			this.isLoading = true;
			let fileName = '';
			if (this.exportType.toLowerCase() === this.EXPORTNAME.EXPENSE) {
				await expenseReport(this.custom, this.scriptSelected)
					.then((result) => {
						if (!this.custom.sendEmail) {
							fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_EXPENSE, this.script.id, this.script.customExportFileName);

							this.forceDownload(
								result,
								fileName +
									'.' +
									this.getExtensionScript(
										OUTPUT_FORMAT_EXPORT.at(this.script.outputFileFormat),
										this.script.customExtension,
										getFileExtension(this.script.fileName)
									)
							);
						} else {
							this.createToastForMobile(this.FormMSG(7, 'Success'), this.FormMSG(8, 'The document has been sent to your email address.'));
						}
						this.$emit('script-export-modal:loading', true);
					})
					.catch((e) => {
						console.error(e);
					})
					.finally(() => (this.isLoading = false));
			} else if (this.exportType.toLowerCase() === this.EXPORTNAME.PO || this.exportType.toLowerCase() === this.EXPORTNAME.PURCHASE_ORDER) {
				await purchaseOrderReport(this.custom, this.scriptSelected)
					.then((result) => {
						if (!this.custom.sendEmail) {
							fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_PO, this.script.id, this.script.customExportFileName);

							this.forceDownload(
								result,
								fileName +
									'.' +
									this.getExtensionScript(
										OUTPUT_FORMAT_EXPORT.at(this.script.outputFileFormat),
										this.script.customExtension,
										getFileExtension(this.script.fileName)
									)
							);
						} else {
							this.createToastForMobile(this.FormMSG(7, 'Success'), this.FormMSG(8, 'The document has been sent to your email address.'));
						}
						this.$emit('script-export-modal:loading', true);
					})
					.catch((e) => {
						console.error(e);
					})
					.finally(() => (this.isLoading = false));
			} else if (this.exportType.toLowerCase() === this.EXPORTNAME.TS || this.exportType.toLowerCase() === this.EXPORTNAME.TIMESHEET) {
				await timeSheetReport(this.custom, this.scriptSelected)
					.then((result) => {
						if (!this.custom.sendEmail) {
							fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_TS, this.script.id, this.script.customExportFileName);

							this.forceDownload(
								result,
								fileName +
									'.' +
									this.getExtensionScript(
										OUTPUT_FORMAT_EXPORT.at(this.script.outputFileFormat),
										this.script.customExtension,
										getFileExtension(this.script.fileName)
									)
							);
						} else {
							this.createToastForMobile(this.FormMSG(7, 'Success'), this.FormMSG(8, 'The document has been sent to your email address.'));
						}
						this.$emit('script-export-modal:loading', true);
					})
					.catch((e) => {
						console.error(e);
					})
					.finally(() => (this.isLoading = false));
			} else if (this.exportType.toLowerCase() === this.EXPORTNAME.USER) {
				await userReport(this.custom, this.scriptSelected)
					.then((result) => {
						if (!this.custom.sendEmail) {
							fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_USER, this.script.id, this.script.customExportFileName);

							this.forceDownload(
								result,
								fileName +
									'.' +
									this.getExtensionScript(
										OUTPUT_FORMAT_EXPORT.at(this.script.outputFileFormat),
										this.script.customExtension,
										getFileExtension(this.script.fileName)
									)
							);
						} else {
							this.createToastForMobile(this.FormMSG(7, 'Success'), this.FormMSG(8, 'The document has been sent to your email address.'));
						}
						this.$emit('script-export-modal:loading', true);
					})
					.catch((e) => {
						console.error(e);
					})
					.finally(() => (this.isLoading = false));
			} else if (this.exportType.toLowerCase() === this.EXPORTNAME.EXPENSE_ITEM) {
				await expenseItemReport(this.custom, this.scriptSelected)
					.then((result) => {
						if (!this.custom.sendEmail) {
							fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_EXPENSE_ITEM, this.script.id, this.script.customExportFileName);

							this.forceDownload(
								result,
								fileName +
									'.' +
									this.getExtensionScript(
										OUTPUT_FORMAT_EXPORT.at(this.script.outputFileFormat),
										this.script.customExtension,
										getFileExtension(this.script.fileName)
									)
							);
						} else {
							this.createToastForMobile(this.FormMSG(7, 'Success'), this.FormMSG(8, 'The document has been sent to your email address.'));
						}
						this.$emit('script-export-modal:loading', true);
					})
					.catch((e) => {
						console.error(e);
					})
					.finally(() => (this.isLoading = false));
			}
		},
		handleCloseLogWindow() {
			this.openLog = false;
			this.$emit('script-export-modal:closed');
		},
		handleScriptSelected(payload) {
			this.script = payload;
		},
		getFileExt(fileName) {
			return getFileExtension(fileName);
		}
	},
	watch: {
		items: {
			handler(items) {
				if (!isNil(items)) {
					this.isSubmitted = false;
					this.scriptSelected = null;
					this.scripts = [
						{
							id: null,
							name: this.FormMSG(5, 'Please select a script...')
						},
						...items
					];
				}
			}
		}
	},
	validations: {
		scriptSelected: {
			required
		}
	}
};
</script>
