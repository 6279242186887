import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const purchaseOrderReport = async (filter, scriptId = null) => {
	const query = gql`
		query ($filter: AdvancedGlobalFilterInput, $scriptId: Int) {
			PoGridReportFromTo(Filter: $filter, ScriptId: $scriptId)
		}
	`;

	let variables = {
		filter
	};

	if (scriptId && +scriptId > 0) {
		variables = {
			...variables,
			scriptId: +scriptId
		};
	}

	const {
		data: { PoGridReportFromTo }
	} = await apollo.query({
		query,
		variables,
		fetchPolicy: 'no-cache'
	});

	return PoGridReportFromTo;
};

export const expenseReport = async (filter, scriptId = null) => {
	const query = gql`
		query ($filter: AdvancedGlobalFilterInput, $scriptId: Int) {
			ExpensesGridReportFromTo(Filter: $filter, ScriptId: $scriptId)
		}
	`;

	let variables = {
		filter
	};

	if (scriptId && +scriptId > 0) {
		variables = {
			...variables,
			scriptId: +scriptId
		};
	}

	const {
		data: { ExpensesGridReportFromTo }
	} = await apollo.query({
		query,
		variables,
		fetchPolicy: 'no-cache'
	});

	return ExpensesGridReportFromTo;
};

export const timeSheetReport = async (filter, scriptId = null) => {
	const query = gql`
		query ($filter: AdvancedGlobalFilterInput, $scriptId: Int) {
			TimesheetGridReportFromTo(Filter: $filter, ScriptId: $scriptId)
		}
	`;

	let variables = {
		filter
	};

	if (scriptId && +scriptId > 0) {
		variables = {
			...variables,
			scriptId: +scriptId
		};
	}

	const {
		data: { TimesheetGridReportFromTo }
	} = await apollo.query({
		query,
		variables,
		fetchPolicy: 'no-cache'
	});

	return TimesheetGridReportFromTo;
};

export const exportPayroll = async ({ startDate, endDate }) => {
	const MUTATION_EXPORT_PAYROLL = gql`
		mutation ($startDate: String!, $endDate: String!) {
			ExportPayCode(StrDate: $startDate, EndDate: $endDate)
		}
	`;

	const {
		data: { ExportPayCode }
	} = await apollo.mutate({
		mutation: MUTATION_EXPORT_PAYROLL,
		variables: {
			startDate,
			endDate
		}
	});

	return ExportPayCode;
};

export const userReport = async (filter, scriptId = null) => {
	const query = gql`
		query ($filter: AdvancedGlobalFilterInput, $scriptId: Int) {
			UserInfoGridReportFromTo(Filter: $filter, ScriptId: $scriptId)
		}
	`;

	let variables = {
		filter
	};

	if (scriptId && +scriptId > 0) {
		variables = {
			...variables,
			scriptId: +scriptId
		};
	}

	const {
		data: { UserInfoGridReportFromTo }
	} = await apollo.query({
		query,
		variables,
		fetchPolicy: 'no-cache'
	});

	return UserInfoGridReportFromTo;
};

export const expenseItemReport = async (filter, scriptId = null) => {
	const query = gql`
		query ($filter: AdvancedGlobalFilterInput, $scriptId: Int) {
			ExpenseItemGridReportFromTo(Filter: $filter, ScriptId: $scriptId)
		}
	`;

	let variables = {
		filter
	};

	if (scriptId && +scriptId > 0) {
		variables = {
			...variables,
			scriptId: +scriptId
		};
	}

	const {
		data: { ExpenseItemGridReportFromTo }
	} = await apollo.query({
		query,
		variables,
		fetchPolicy: 'no-cache'
	});

	return ExpenseItemGridReportFromTo;
};
